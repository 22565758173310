<template>
  <el-tooltip
    class="item" effect="dark" placement="right-end"
    :content="(status ? 'Locked' : 'Unlocked') + ', Tap to '+ (status ? 'Unlock' : 'Lock') + '!'"
    :visible-arrow="false"
  >
    <i v-show="$store.state.authUser ? $store.state.authUser.type == 'sub_user' : false"
       @click="triggerToggle"
       :class="{'fa fa-lock': status, 'fa fa-lock-open': !status, 'pointer-events-none': isActive}"
       class="lock-icon-switch-class"
    />
  </el-tooltip>
</template>

<script>
export default {
  name: "ToggleLockSwitch",
  props: {
    isLocked: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return this.isLocked
    },
    active() {
      return this.isActive
    },
  },
  methods: {
    triggerToggle() {
      this.$emit('handleToggle', this.status)
    },
  },
}
</script>

<style scoped>
.lock-icon-switch-class {
  padding: 6px 0 0 10px;
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}
</style>
