<template>
  <div class="container-fluid">
    <div class="pt-4">
      <h1>
        Automatic Messages
      </h1>
    </div>
    <div class="mt-3">
      <!--   Tab panes   -->
      <el-tabs
        class="tab_main messages"
        type="border-card"
        @tab-click="handleTabClick"
      >
        <!--    Weight Condition Pane    -->
        <el-tab-pane
          :label="'Weight Conditions'"
          key="weight"
        >
          <weight-reminder-automatic-message
            class="status-toggle weight-conditions"
            :weight-reminder="weightReminderMessage"
            @update-message-field-status="updateWeightReminderMessageFieldStatus(...arguments)"
            @update-weight-reminder="updateWeightReminder"
          >
          </weight-reminder-automatic-message>
          <template v-for="(message, index) in weightMessages">
            <single-message
              class="status-toggle weight-conditions"
              @toggleMessageLock="toggleMessageFieldStatus(...arguments, 'weight', index, weightMessages)"
              :message-field-status="message.hasOwnProperty('fieldStatus') ? message.fieldStatus : true"
              :key="message.key"
              :weightFixed="message.weightFixed"
              :constraint="message.constraint"
              :message="message.message"
              :status="message.status"
              :constraintLabel="constraintLabel(message.labelHelper,'weight')"
              messageLabel="pounds from starting weight then this message"
              @stateChange="updateCall('weight',...arguments)"
            >
            </single-message>
          </template>
        </el-tab-pane>
        <el-tab-pane
          :label="'Nutrition Conditions'"
          key="nutrition"
        >
          <template v-for="(message, index) in nutritionMessages">
            <single-message
              class="status-toggle weight-conditions"
              @toggleMessageLock="toggleMessageFieldStatus(...arguments, 'nutrition', index, nutritionMessages)"
              :message-field-status="message.hasOwnProperty('fieldStatus') ? message.fieldStatus : true"
              :key="message.key"
              :weightFixed="message.weightFixed"
              :constraint="message.constraint"
              :message="message.message"
              :status="message.status"
              :constraintLabel="constraintLabel(message.labelHelper,'nutrition')"
              messageLabel="days then this message:"
              @stateChange="updateCall('nutrition',...arguments)"
            >
            </single-message>
          </template>
        </el-tab-pane>
        <el-tab-pane
          :label="'Surgery Reminders'"
          key="surgery"
        >
          <template v-for="(message, index) in surgeryMessages">
            <single-message
              class="status-toggle weight-conditions"
              @toggleMessageLock="toggleMessageFieldStatus(...arguments, 'surgery', index, surgeryMessages)"
              :message-field-status="message.hasOwnProperty('fieldStatus') ? message.fieldStatus : true"
              :key="message.key"
              :weightFixed="message.weightFixed"
              :constraint="message.constraint"
              :message="message.message"
              :status="message.status"
              :constraintLabel="constraintLabel(message.labelHelper,'surgery')"
              messageLabel="Message:"
              @stateChange="updateCall('surgery',...arguments)"
            >
            </single-message>
          </template>
        </el-tab-pane>
        <el-tab-pane
          :label="'Welcome Message'"
          key="welcome"
        >
          <template v-for="(message, index) in welcomeMessages">
            <single-message
              class="status-toggle weight-conditions"
              @toggleMessageLock="toggleMessageFieldStatus(...arguments, 'welcome', index, welcomeMessages)"
              :message-field-status="message.hasOwnProperty('fieldStatus') ? message.fieldStatus : true"
              :key="message.key"
              :weightFixed="message.weightFixed"
              :constraint="message.constraint"
              :message="message.message"
              :status="message.status"
              constraintLabel="Connect-Program"
              messageLabel="Message:"
              @stateChange="updateCall('welcome',...arguments)"
            >
            </single-message>
          </template>
        </el-tab-pane>
        <el-tab-pane
          :label="'Non Surgical Reminders'"
          key="nonSurgery"
        >
          <template v-for="(message, index) in nonSurgeryMessages">
            <single-message
              class="status-toggle weight-conditions"
              @toggleMessageLock="toggleMessageFieldStatus(...arguments, 'nonSurgery', index, nonSurgeryMessages)"
              :message-field-status="message.hasOwnProperty('fieldStatus') ? message.fieldStatus : true"
              :key="message.key"
              :weightFixed="message.weightFixed"
              :constraint="message.constraint"
              :message="message.message"
              :status="message.status"
              constraintLabel="Non-Surgical"
              messageLabel="Message:"
              @stateChange="updateCall('nonSurgery',...arguments)"
            >
            </single-message>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import SingleMessage from "../../Components/Messages/SingleMessage";
import WeightReminderAutomaticMessage from "@/views/Components/Messages/WeightReminderAutomaticMessage";

export default {
  name: "AutomaticMessages",
  components: {SingleMessage, WeightReminderAutomaticMessage},
  data() {
    return {
      weightMessages: null,
      nutritionMessages: null,
      welcomeMessages: null,
      surgeryMessages: null,
      nonSurgeryMessages: null,
      weightReminderMessage: {
        message: 'It\'s time to weigh-in. Log your weight in the app.',
        after_week: 1,
        status: false,
        message_field_status: 1,
      }
    }
  },
  methods: {
    constraintLabel(labelFlag,constraintKey){
      if (constraintKey === 'weight'){
        return (labelFlag)?'If weight goes down':'If weight goes up'
      }else if (constraintKey === 'nutrition'){
        return (labelFlag)?'If patient does not log food for':
          'If patient logs food for'
      }else if (constraintKey === 'surgery'){
        return (labelFlag)?'Before Surgery':'After Surgery'
      }
    },
    setMessages(datatype, data){
      if (datatype === 'weight'){
        this.weightMessages = data
      }else if (datatype === 'nutrition'){
        this.nutritionMessages = data
      } else if (datatype === 'welcome'){
        this.welcomeMessages = data
      } else if (datatype === 'surgery'){
        this.surgeryMessages = data
      } else if (datatype === 'nonSurgery'){
        this.nonSurgeryMessages = data
      }
    },
    updateCall(destination,key,field,value){
      const payload = {
        _method: 'PUT',
        key: key,
        field: field,
        value: value
      }
      if (payload.value === '') return
      axios.post(`${this.$store.getters.getBaseUrl}/api/AutomaticMessages/${destination}-condition/update`,payload)
        .then(response => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
        })
        .catch( error => {
          console.error(error.message,error.response.data)
        })
    },
    handleTabClick(obj){
      this.fetchMessages(obj.$vnode.key)
    },
    fetchMessages(destination){
      axios.get(`${this.$store.getters.getBaseUrl}/api/AutomaticMessages/${destination}-condition`)
      .then( response => {
        this.$store.commit('toggleSessionCheck', true)
        this.$store.commit('updateSessionTime', 0)
        this.setMessages(destination,response.data.weightConditions)
        if(destination == 'weight') this.fetchWeightReminderMessage()
      })
      .catch( error => {
        console.error(error.message,error.response.data)
      })
    },

    /**
     * Update the Weight Reminder Message
     * @param {string} field - Field Name
     * @param value - Value of the Field
     */
    updateWeightReminder(field, value) {
      let vm = this
      const payload = {
        field,
        value,
        '_method': 'PATCH'
      }
      vm.$store.dispatch('_updateWeightReminderMessage', payload)
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Weight Reminder Message updated successfully.'
        })
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Weight Reminder Message',
          message: message
        })
      })
    },

    /**
     * Fetch the Weight Reminder Message
     */
    fetchWeightReminderMessage() {
      let vm = this
      vm.$store.dispatch('_getWeightReminderMessage')
      .then(response => {
        if (response.data.data) Object.assign(vm.weightReminderMessage, response.data.data)
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Weight Reminder Message',
          message: message
        })
      })
    },

    /**
     * Toggle the message field status
     * @param status
     * @param key
     * @param label
     * @param index
     * @param messages
     */
    toggleMessageFieldStatus({status, key}, label, index, messages) {
      let vm = this
      const payload = {
        status, key, label, field: 'message'
      }
      vm.$store.dispatch('_updateAutomaticMessageFieldStatus', payload)
        .then(response => {
          messages[index]['fieldStatus'] = status
          vm.$notify.success({
            title: 'Success',
            message: 'Message Field ' + (status == true ? 'Unlocked' : 'Locked') +' Successfully'
          })
        })
        .catch(error => {
          messages[index]['fieldStatus'] = !status
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Message Field Status',
            message: message
          })
        })
    },

    /**
     * Update the Weight Reminder Message Field Status
     * @param status
     */
    updateWeightReminderMessageFieldStatus(status) {
      let vm = this
      vm.$store.dispatch('_updateWeightReminderMessageFieldStatus', {status})
        .then(response => {
          this.weightReminderMessage.message_field_status = status
          vm.$notify.success({
            title: 'Success',
            message: 'Message Field ' + (status == true ? 'Unlocked' : 'Locked') +' Successfully'
          })
        })
        .catch(error => {
          this.weightReminderMessage.message_field_status = !status
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Message Field Status',
            message: message
          })
        })
    },
  },
  mounted() {
    this.fetchMessages('weight')
  }
}

</script>

<style>
  @import './messages.scss';
</style>


