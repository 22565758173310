<template>
  <div>
    <div class="message-row">
      <!--     Label Row       -->
      <div class="d-flex justify-content-around">
        <div class="messages-constraint">
          <p class="messages-input-label">{{ constraintLabel }}</p>
        </div>
        <div class="messages-text">
          <p class="messages-input-label">
            {{ messageLabel }}
          </p>
        </div>
        <div class="toggle">
        </div>
      </div>
      <div class="d-flex justify-content-around">
        <div class="messages-constraint messages-input-y">
          <base-input
            v-model="conditionValue"
            :disabled="weightFixed || !statusValue"
            @input="debouncedInput('constraint', conditionValue)"
          ></base-input>
        </div>
        <div class="messages-text messages-input-y d-flex">
          <base-input
            class="w-100"
            v-model="messageValue"
            :disabled="!statusValue || (!messageFieldStatus && $store.state.authUser.type == 'program_user')"
            @input="debouncedInput('message', messageValue)"
          ></base-input>
          <toggle-lock-switch :is-locked="!messageFieldStatus" :is-active="!statusValue" @handleToggle="toggleMessageLock(...arguments, $vnode.key)"/>
        </div>
        <div class="d-flex mt-1 toggle">
          <span class="inactive-text pr-3">Off</span>
          <base-switch class="status-toggle"
                       ref="templateSwitcher"
                       v-model="statusValue"
                       @input="$emit('stateChange',$vnode.key,'status', statusValue)"
          >
          </base-switch>
          <span class="active-text pl-3">On</span>
        </div>
      </div>
    </div>
    <div class="messages-border-bottom-line"></div>
  </div>
</template>

<script>
import baseSwitchLabelMixin from "@/mixins/baseSwitchLabelMixin";
import ToggleLockSwitch from "@/components/ToggleLockSwitch";

export default {
  name: "SingleMessage",
  components: {ToggleLockSwitch},
  props: {
    // weight cant be changed
    weightFixed: true,
    constraint: 0,
    message: '',
    status: true,
    constraintLabel: '',
    messageLabel: '',
    messageFieldStatus: true,
  },
  data() {
    return {
      conditionValue: 0,
      messageValue: '',
      statusValue: true,
      debouncedInput: _.debounce(
        (typename,value) => {this.$emit('stateChange',this.$vnode.key,typename, value)}
        , 800)
    }
  },
  mounted() {
    this.customizeSwitch()
    this.initializeData()
  },
  methods: {
    initializeData() {
      this.conditionValue = this.constraint
      this.messageValue = this.message
      this.statusValue = this.status
    },
    toggleMessageLock(status, key) {
      this.$emit('toggleMessageLock', {status, key})
    }
  },
  mixins: [baseSwitchLabelMixin]
}
</script>

<style scoped>
.messages-constraint {
  width: 27.5%;
}

.messages-text {
  width: 48.3%;
}

.messages-input-label {
  font: normal normal 600 10px/9px Open Sans;
  letter-spacing: 1px;
  color: hsl(212, 17%, 60%);
  opacity: 1;
}


.messages-border-bottom-line {
  width: 100%;
  border-bottom: solid 1px hsl(220, 16%, 96%);
}

.toggle{
  width: 12%;
  min-width: 129px;
}

.message-row{
  padding-bottom: 20px;
  padding-top: 20px;
  min-width: 792px
}

</style>
