export default {
  methods: {
    /**
     * Customizing the Switch
     */
    customizeSwitch() {
      let switcher = this.$refs.templateSwitcher.$el.children[1];
      switcher.setAttribute('data-label-off', '')
      switcher.setAttribute('data-label-on', '')
    },
  }
}
