<template>
  <div>
    <div class="message-row">
      <div class="d-flex justify-content-around">
        <div class="messages-constraint">
          <p class="messages-input-label">Weigh-In Reminder</p>
        </div>
        <div class="messages-text">
          <p class="messages-input-label">Send this message</p>
        </div>
        <div class="toggle">
        </div>
      </div>
      <div class="d-flex justify-content-around">
        <div class="messages-constraint">
          <select name="afterWeeks" class="form-control py-0" v-model="afterWeeks" :disabled="!status"
                  @change="$emit('update-weight-reminder', 'after_week', afterWeeks)">
            <option value="1">Every Week on Monday</option>
            <option value="2">Every Two Weeks on Monday</option>
            <option value="4">Every Four Weeks on Monday</option>
          </select>
        </div>
        <div class="messages-text messages-input-y d-flex">
          <base-input
              class="w-100"
              v-model="message"
              placeholder="Input Message..."
              :disabled="!status || (!messageFieldStatus && $store.state.authUser.type == 'program_user')"
              @input="debouncedInput('message', message)"
          ></base-input>
          <toggle-lock-switch :is-locked="!messageFieldStatus" :is-active="!status" @handleToggle="toggleMessageFieldStatus(...arguments)"/>
        </div>
        <div class="d-flex mt-1 toggle">
          <span class="inactive-text pr-3">Off</span>
          <base-switch class="status-toggle"
                       ref="templateSwitcher"
                       v-model="status"
                       @input="updateWeightReminder('status', status)"
          >
          </base-switch>
          <span class="active-text pl-3">On</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseSwitchLabelMixin from "@/mixins/baseSwitchLabelMixin";
import ToggleLockSwitch from "@/components/ToggleLockSwitch";
export default {
  name: "WeightReminderAutomaticMessage",
  props: ['weightReminder'],
  components: {ToggleLockSwitch},
  data() {
    return {
      debouncedInput: _.debounce(
          (field, value) => {
            this.updateWeightReminder(field, value)
          }, 800)
    }
  },
  computed: {
    message: {
      get() {
        return this.weightReminder.message
      },
      set(message) {
        this.weightReminder.message = message
      },
    },

    afterWeeks: {
      get() {
        return this.weightReminder.after_week
      },
      set(value) {
        this.weightReminder.after_week = value
      }
    },

    status: {
      get() {
        return Boolean(this.weightReminder.status)
      },
      set(status) {
        this.weightReminder.status = status
      }
    },

    messageFieldStatus: {
      get() {
        return this.weightReminder.message_field_status
      },
      set(status) {
        this.weightReminder.message_field_status = status
      }
    }
  },
  mounted() {
    this.customizeSwitch()
  },
  methods: {
    /**
     * Update the weight reminder
     * @param {string} field - Field Namemixins
     * @param value - Value of the Field
     */
    updateWeightReminder(field, value) {
      this.$emit('update-weight-reminder', field, value)
    },

    /**
     * Update the message field status
     * @param status
     */
    toggleMessageFieldStatus(status) {
      this.$emit('update-message-field-status', status)
    },
  },
  mixins: [baseSwitchLabelMixin]
}
</script>

<style scoped>

select {
  height: 29px;
}

.messages-constraint {
  width: 27.5%;
}

.messages-text {
  width: 48.3%;
}

.messages-input-label {
  font: normal normal 600 10px/9px Open Sans;
  letter-spacing: 1px;
  color: hsl(212, 17%, 60%);
  opacity: 1;
}

.toggle {
  width: 12%;
  min-width: 129px;
}

.message-row {
  padding-bottom: 20px;
  padding-top: 20px;
  min-width: 792px
}

</style>
